 /*
 * App Style
 *
 * The base style for the application.
 *
 */
@charset "utf-8/index";

@import '_variables.scss';
//@import '_base.scss';
//@import '_typeography.scss';
//@import '_input.scss';

/*MIXINS AND CUSTOM SCSS*/

@font-face {
  font-family: 'GT Sectra';
  font-style: normal;
  font-weight: regular;
  src: url('../fonts/GT-Sectra-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/GT-Sectra-Regular.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'GT Sectra';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/GT-Sectra-Fine-Bold-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/GT-Sectra-Fine-Bold-Italic.woff') format('woff'), /* Modern Browsers */
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: bolder;
  src: local('Gotham'), local('gotham'),
  url('../fonts/Gotham-Medium.otf') format('opentype'), /* Safari, Android, iOS */
  url('../fonts/Gotham-Medium.woff') format('woff'), /* Modern Browsers */
}

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}

/* Chrome, Safari, Opera */
@-webkit-keyframes party {
  0%   {color: $ft-pink;}
  50%   {color: $ft-blue;}
  100%   {color: $ft-pink;}
}

@-moz-keyframes party {
  0%   {color: $ft-pink;}
  50%   {color: $ft-blue;}
  100%   {color: $ft-pink;}
}

/* Standard syntax */
@keyframes party {
  0%   {color: $ft-pink;}
  50%   {color: $ft-blue;}
  100%   {color: $ft-pink;}
}

$text-grey: #EAEAEA;

/*IMPORT THE REST OF THE FUCKING OWL*/
@import '~bulma/css/bulma.css';

/*HEADER NAVIGATION*/

.navbar {
  min-height: 3.75rem;
}

.navbar-item {
  font-family: 'Gotham', serif;
  font-weight: bolder;
  font-size: .8125rem;
}

.navbar-brand{
  margin-right:1rem;
}

.button {
  font-family: 'Gotham', serif;
  font-weight: bolder;
  font-size: .8125rem;
  font-size: .8125rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.button.gradient {
  color: #ffffff;
  @include ftGradientBG;
  height: 2.5rem;
  y:20;
  border: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.donate-float {
  position: absolute;
  float: right;
}

.navbar-item img {
  max-height: 2.5rem;
  padding: 0 20px;
}

.headerLogo {
  margin-top: 10px;
  position: absolute;
  left: 50%; /* Start at 50% of browser window */
  margin-left: -18px;
}


.footer {
  font-family: 'Gotham', serif;
  font-weight: bolder;
  font-size: 1rem;
  color: grey;
}

body {
  background: black;
  margin: 0;
  padding: 0;
  font-family: 'Gotham', serif;
  font-weight: bolder;
  font-size: .8125rem;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.1s;
}

/*TYPOGRAPHY*/
.content h1 {
  font-size: 4rem;
  font-family: 'GT Sectra', serif;
  font-weight: bold;
  font-style: italic;
}

h1.title {
  color: $ft-pink;
  font-size: 3rem;
  font-family: 'GT Sectra', serif;
  font-weight: regular;
}

h2.subtitle {
  color: white;
  font-size: 2.5rem;
  font-family: 'GT Sectra', serif;
  font-weight: bold;
  font-style: italic;
}

h3.question {  color: white;
  font-size: 1.75rem;
  font-family: 'GT Sectra', serif;
  font-weight: bold;
  font-style: italic;}

  h3.label {
    color: black;
    font-size: 1rem;
    font-family: 'Gotham', serif;
    font-weight: bolder;
  }

  /*h1 span {
  color: black;
  background-color: white;
  padding: 4px 10px;
  margin: 10px 0px;
}

h2 span {
color: black;
background-color: white;
padding: 4px 10px;
margin: 10px 0px;
}*/

p {
  font-family: 'GT Sectra', serif;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.8rem;
}

a {
  color: $ft-pink;
}

.has-text-link {
  padding: .05rem 1rem;
  font-family: 'Gotham', serif;
  font-weight: bolder;
  font-size: .8125rem;
  text-transform: uppercase;
}

span.line {
  display: inline-block;
}


#missionStatement{
  top: 200px;
}


.no-padding-top {
  padding-top: 0px;
}

.has-text-light {
  color: $text-grey;
}

.image-tile {

}

.is-constrained {
  margin: 0 auto;
  max-width: 1200px;
  //  padding: 1.5rem;
}

.headerImg {
  padding-bottom: 100px;
  margin-bottom: -100px;
  height: auto;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  /*background-position: center;
  background-image: url('./images/headerImg.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

/* GENERAL PAGE */
.hero-image {
  height: 90vh;
  overflow: visible;
  object-fit: cover;
  object-position: center;
}

.field.has-addons.flex-middle {
  justify-content: center;
}


/*HEADER*/
img.page-header {
  height: 400px !important;
  overflow: hidden !important;
  object-fit: cover !important;
  position: relative;
}

/*HERO IMAGE*/
.hero-body.page-header-title {
  top: 26vh;
}

.page-header-title {
  position: absolute;
  top: 200px;
  width: 100%;
  left: 50%;
  transform: translate(-50%,0);
  text-align: center;
}

/*FOOTER*/
.footer{
  margin-top: 20px;
}

/*.footer::before {
content: url('./images/headerImg.png');
width:100%;
height:40px;
}*/

/* BLOG*/

article {
  background: white;
}

.blog-item-header {
  height: 400px;
  overflow: hidden;
  object-fit: cover;
}

.blog-post-header {
  height: 500px;
  overflow: hidden;
  object-fit: cover;
}

article.section {
  padding: 0 0 3px 0;
}

/*HEADER NAVIGATION*/

.sponsor {
  padding: 30px 0;
}

.sponsorImg {
  height: auto !important;
}


/*SECTIONS*/

#subscribeOuter {
  padding:100px 1.5rem;
}

#subscribe {
  max-width: 400px;
}

#subscribeButton {
  height: 100%;
}

#missionStatement {
  padding-top: 40px;
  width:100vw;
}



.field.has-addons .control:not(:last-child) {
  width: 100%;
}


::selection {
  background: $ft-pink; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: $ft-pink; /* Gecko Browsers */
}

button:hover {
  -webkit-animation: party 2s;
  -moz-animation: party 2s;
  animation: party 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

a:hover {
  -webkit-animation: party 2s;
  -moz-animation: party 2s;
  animation: party 2s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  width: calc(100%-600px);
  margin-bottom: 1rem !important;
}
